import useAmpComponent from '@/hooks/useAmpComponent';
import { sponsorCategoryData, categorySponsorDataItemType, sponsoredCategoryType } from '@/lib/sponsors';
import classNames from '@/utils/className';

type sponsoredCategoryHeaderProps = {
  sponsoredCategory: sponsoredCategoryType;
  logoSize?: 'small' | 'large' | 'responsive';
};

export const SponseredCategoryHeader = ({ sponsoredCategory, logoSize = undefined }: sponsoredCategoryHeaderProps) => {
  const { ImageComponent } = useAmpComponent();

  const sponsorData: categorySponsorDataItemType[] = sponsorCategoryData[sponsoredCategory];
  const largeLogoClass = 'first:[&>figure]:hidden last:[&>figure]:block';
  const smallLogoClass = 'first:[&>figure]:block last:[&>figure]:hidden';
  const responsiveLogoClass = 'first:[&>figure]:block last:[&>figure]:hidden tablet:first:[&>figure]:hidden tablet:last:[&>figure]:block';

  const logoClass = {
    small: smallLogoClass,
    large: largeLogoClass,
    responsive: responsiveLogoClass,
  };

  const logoSizeCalc = logoSize ? logoSize : sponsorData && sponsorData.length > 1 ? 'responsive' : 'large';

  if (!sponsorData) return null;

  return (
    <div className="mb-4 flex flex-col items-end justify-center">
      <span className="mb-1 text-[10px] font-light text-mglGray500">Conteúdo apoiado por</span>
      <div className="flex flex-row gap-2">
        {sponsorData.map((sponsor, index) => (
          <a key={`${index}`} className={classNames('flex flex-row items-center ', logoClass[logoSizeCalc])} href={sponsor.url}>
            <ImageComponent
              title={sponsor.title}
              src={sponsor.image.mobile.src}
              blurhash={sponsor.image.mobile.blurhash}
              height={sponsor.image.mobile.height}
              width={sponsor.image.mobile.width}
            />

            <ImageComponent
              title={sponsor.title}
              src={sponsor.image.desktop.src}
              blurhash={sponsor.image.desktop.blurhash}
              height={sponsor.image.desktop.height}
              width={sponsor.image.desktop.width}
            />
          </a>
        ))}
      </div>
      <hr className="mt-4 w-full border-t border-mglGray100 border-opacity-20" />
    </div>
  );
};
